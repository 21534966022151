const cuisineMap = {
    African: "african",
    "New American": "newamerican",
    "Traditional American": "tradamerican",
    "Asian fusion": "asianfusion",
    Australian: "australian",
    Austrian: "austrian",
    BBQ: "bbq",
    British: "british",
    Buffets: "buffets",
    Breakfast: "breakfast_brunch",
    Cafes: "cafes",
    Burgers: "burgers",
    Cajun: "cajun",
    Cambodian: "cambodian",
    Caribbean: "caribbean",
    Chinese: "chinese",
    "Comfort Food": "comfortfood",
    Delis: "delis",
    Diners: "diners",
    French: "french",
    German: "german",
    Hawaiian: "hawaiian",
    Honduran: "honduran",
    "Hot pot": "hotpot",
    Hungarian: "hungarian",
    Indian: "indpak",
    Indonesian: "indonesian",
    Irish: "irish",
    Italian: "italian",
    Japanese: "japanese",
    Korean: "korean",
    Laotian: "laotian",
    Latin: "latin",
    Malaysian: "malaysian",
    Mediterranean: "mediterranean",
    Mexican: "mexican",
    Mongolian: "mongolian",
    "New Mexican": "newmexican",
    Nicaraguan: "nicaraguan",
    Noodles: "noodles",
    Asian: "panasian",
    Peruvian: "peruvian",
    Pizza: "pizza",
    Polynesian: "polynesian",
    Portuguese: "portuguese",
    Russian: "russian",
    Salad: "salad",
    Sandwiches: "sandwiches",
    Scandinavian: "scandinavian",
    Scottish: "scottish",
    Seafood: "seafood",
    Singaporean: "singaporean",
    Somali: "somali",
    "Soul food": "soulfood",
    Soup: "soup",
    Southern: "southern",
    Spanish: "spanish",
    Steak: "steak",
    Sushi: "sushi",
    Syrian: "syrian",
    Taiwanese: "taiwanese",
    Tapas: "tapas",
    "Tex mex": "tex-mex",
    Thai: "thai",
    Norwegian: "norwegian",
    Turkish: "turkish",
    Ukranian: "ukrainian",
    Vietnamese: "vietnamese",
    Wraps: "wraps"
  };

  export default cuisineMap;